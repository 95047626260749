import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyDYirmfKMSBcX0W5RhW1S3RLUZeliYIbSU',
  authDomain: 'codex-del-salvador.firebaseapp.com',
  projectId: 'codex-del-salvador',
  storageBucket: 'codex-del-salvador.appspot.com',
  messagingSenderId: '337243253180',
  appId: '1:337243253180:web:48eb24302f1ef1dc0fe3b9',
  measurementId: 'G-VEMJEG46CN',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
